import "../styles/fonts.css";
import final from "../videos/final.mp4"

const Thanks = () => {
  return (
    <div className="bg-secondary pt-8 pb-8 sm:pt-36 sm:pb-36 2xl:py-72">
      <h1 className="text-center text-white text-4xl poppins-bold">Thank you for signing up!</h1>
      <p className="poppins-regular text-center text-xl mb-4">Much more to come soon!</p>
      <video controls src={final} className="sm:w-3/4 md:w-1/2 lg:w-1/3 m-auto"/>
      <p className="poppins-regular text-center text-xl mt-4">We invite you to fill out an optional welcome form to help us figure out what type of content interests our audience.</p>
      <div className="text-center mt-4"><a href="/form" className="bg-main text-white p-2 rounded-xl poppins-regular">Complete Form</a></div>
    </div>
  );
};

export default Thanks;
