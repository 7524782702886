import { useEffect, useState } from "react";
import "../styles/fonts.css";
import intro from "../videos/intro.mp4";
import vidThumb from "../images/VidThumb.png"

const Landing = () => {
  const [loading, setLoading] = useState(true);
  const [videos, setVideos] = useState([]);

  const fetchVideos = async () => {
    let res = await fetch(process.env.REACT_APP_API_URL + "/recentvideos");
    let json = await res.json();
    setVideos(json.items.slice(0, 4));
    console.log(videos);
    setLoading(false);
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  return (
    <div>
      <div className="bg-secondary pt-24 pb-24 text-center">
        <h1 className=" text-white text-4xl poppins-bold">
          Wealth Adventures Site Coming Soon!
        </h1>
        <h2 className="text-lg poppins-regular">
          Sign up now to start your wealth adventure and receive updates about
          the future of the site!
        </h2>
        <div className="mt-8 mb-16">
          <video poster={vidThumb} controls src={intro} className="w-1/2 m-auto" />
        </div>
        <a
          href="/signup"
          className="bg-main text-white poppins-regular p-2 rounded-xl"
        >
          Sign up today!
        </a>
      </div>
      <div className="bg-main pt-24 pb-24">
        <h1 className="text-center text-white text-4xl poppins-bold">
          What's to Come
        </h1>
        <div className="grid  grid-cols-1 sm:grid-cols-2 pt-8 ml-24 mr-24 gap-8">
          <div className="text-white">
            <h1 className="poppins-regular text-lg">Courses</h1>
            <p>
              We plan to make courses to help you succeed in your wealth
              adventure. These courses will be desinged for investors of all
              skill levels and will help you learn more about personal finance.
            </p>
          </div>
          <div className="text-white">
            <h1 className="poppins-regular text-lg">Articles</h1>
            <p>
              Simple easy to read articles about investing and current events.
            </p>
          </div>
          <div className="text-white">
            <h1 className="poppins-regular text-lg">
              Mentorship and Private Community
            </h1>
            <p>
              Looking for like-minded investors to discuss your ideas? Looking
              for 1 on 1 coaching? This will be your place!
            </p>
          </div>
          <div className="text-white">
            <h1 className="poppins-regular text-lg">
              Tools, Calculators, and Quizzes
            </h1>
            <p>
              Know your numbers, understand the trades, and make learning
              interactive!
            </p>
          </div>
        </div>
      </div>
      <div className="bg-secondary pt-24 pb-24 text-center">
        <h1 className="text-white text-4xl poppins-bold">Why Sign Up Today?</h1>
        <p className=" text-lg text-white mb-12 mt-2 poppins-regular sm:mr-36 sm:ml-36">
          By signing up today, you will be automatically signed up for the
          Wealth Adventure email list. Articles will follow that will provide updates as we progress the site. In addition, you can complete an optional interest form
          which will give us an idea of what content our audience is most
          interested in learning about. As an early member you will also receive discounts when
          the full version of the Wealth Adventures site is complete.
        </p>
        <a
          href="/signup"
          className="bg-main text-white poppins-regular p-2 rounded-xl"
        >
          Sign up today!
        </a>
      </div>
      <div className="bg-main pt-24 pb-24">
        <h1 className="text-white text-4xl text-center poppins-bold">
          Recent videos from Wealth Adventures
        </h1>
        <div>
          {loading ? (
            <div className="text-white text-4xl text-center poppins-bold">
              Loading...
            </div>
          ) : (
            <div className="sm:grid grid-cols-4 pt-8 ml-24 mr-24 gap-8">
              {videos.map((video) => (
                <div className="bg-secondary pb-4 mb-4 sm:mb-0 rounded-xl">
                  <img
                    className="w-full"
                    src={video.snippet.thumbnails.medium.url}
                  />
                  <div className="text-center">
                    <h1 className="text-white ml-2 mr-2 mb-4">
                      {video.snippet.title}
                    </h1>
                    <a
                      target="_blank"
                      href={
                        "https://www.youtube.com/watch?v=" +
                        video.contentDetails.videoId
                      }
                      className="text-white bg-main rounded-lg p-2"
                    >
                      Watch Now!
                    </a>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="mt-24 text-center">
          <a
            target="_blank"
            href="https://www.youtube.com/@wealthadventures"
            className="bg-secondary poppins-regular p-2 rounded-lg "
          >
            View More
          </a>
        </div>
      </div>
      <div className="bg-secondary pt-24 pb-24">
        <h1 className="text-white text-4xl text-center poppins-bold">
          What are you waiting for?
        </h1>
        <div className="text-center mt-12">
          <a
            href="/signup"
            className="bg-main text-white poppins-regular p-2 text-2xl rounded-xl"
          >
            Sign up today!
          </a>
        </div>
      </div>
    </div>
  );
};

export default Landing;
