import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import "../styles/fonts.css";

const Footer = () => {
  return (
    <div className="bg-main pt-12 pb-12">
      <h1 className="text-white text-2xl text-center poppins-bold">
        Wealth Adventures
      </h1>
      <p className="text-center text-white">
        Website built by Paragon Twist LLC
      </p>
      <div className="flex justify-center">
        <div className="flex gap-4 mt-1">
          <a href="https://x.com/DavidGr74529022" target="_blank">
            <FontAwesomeIcon className="text-3xl text-white" icon={faXTwitter} />
          </a>
          <a
            href="https://www.instagram.com/dgwealthadventures/"
            target="_blank"
          >
            <FontAwesomeIcon
              className="text-3xl text-white"
              icon={faInstagram}
            />
          </a>
          <a href="https://www.youtube.com/@wealthadventures/" target="_blank">
            <FontAwesomeIcon className="text-3xl text-white" icon={faYoutube} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
