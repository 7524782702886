import "../styles/fonts.css";

const Header = () => {
  return (
    <div className="text-center text-xl poppins-bold bg-main text-white pt-4 pb-4 h-full">
      Wealth Adventures
    </div>
  );
};

export default Header;
