import "../styles/fonts.css";
import { getJWT } from "../authentication/jwt";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const Form = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const submitForm = async (event) => {
    event.preventDefault();

    setLoading(true);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + getJWT());

    let age = document.getElementById("age").value;

    let interest = [];
    for (let i = 0; i < document.getElementsByClassName("check").length; i++) {
      let item = document.getElementsByClassName("check")[i];
      if (item.checked) {
        interest.push(item.id);
      }
    }

    let location = document.getElementById("location").value;
    let comments = document.getElementById("comments").value;

    const raw = JSON.stringify({
      age: age,
      interest: interest,
      location: location,
      additionalComments: comments,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    let request = await fetch(
      process.env.REACT_APP_API_URL + "/forms/answerForm",
      requestOptions
    );
    let results = await request.json();
    let message = results.msg;
    if (message == "success") {
      setMessage("Successfully submited form!");
      setLoading(false);
    } else {
      setMessage("Error: " + message);
      setLoading(false);
    }
  };

  if (!loading && !message) {
    return (
      <div className="bg-secondary pt-24 pb-24">
        <h1 className="text-center text-xl sm:text-3xl sm:ml-52 sm:mr-52 mb-12 poppins-bold text-white">
          Thank you for deciding to fill out our optional welcome form. Feel
          free to answer as many or as few questions as you would like.
        </h1>
        <div>
          <h1 className="text-center text-2xl text-white">
            Please enter your age
          </h1>
          <div className="grid mt-4">
            <input
              className="m-auto w-24 text-xl poppins-regular text-center border-b-2 border-white text-white bg-secondary focus:outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              type="number"
              id="age"
            />
          </div>
          <h1 className="text-center text-2xl text-white mt-12">
            Please select your interests
          </h1>
          <div className="flex">
            <div className="mt-4 inline-block m-auto">
              <div className="flex ">
                <input class="check" type="checkbox" className="" id="Stocks" />
                <p className="ml-2 text-white">Stocks</p>
              </div>
              <div className="flex ">
                <input class="check" type="checkbox" id="ETFs" />
                <p className="ml-2 text-white">ETFs</p>
              </div>
              <div className="flex ">
                <input class="check" type="checkbox" id="Options" />
                <p className="ml-2 text-white">Options</p>
              </div>
              <div className="flex ">
                <input class="check" type="checkbox" id="Insurance" />
                <p className="ml-2 text-white">Insurance</p>
              </div>
              <div className="flex ">
                <input class="check" type="checkbox" id="Money Management" />
                <p className="ml-2 text-white">Professional Money Management</p>
              </div>
              <div className="flex ">
                <input class="check" type="checkbox" id="Real Estate" />
                <p className="ml-2 text-white">Real Estate</p>
              </div>
              <div className="flex ">
                <input class="check" type="checkbox" id="Mutual Funds" />
                <p className="ml-2 text-white">Mutual Funds</p>
              </div>
              <div className="flex ">
                <input class="check" type="checkbox" id="Fixed Income" />
                <p className="ml-2 text-white">Fixed Income</p>
              </div>
              <div className="flex ">
                <input class="check" type="checkbox" id="Crypto" />
                <p className="ml-2 text-white">Crypto</p>
              </div>
              <div className="flex ">
                <input class="check" type="checkbox" id="1-1 Training" />
                <p className="ml-2 text-white">1-1 Training</p>
              </div>
              <div className="flex ">
                <input class="check" type="checkbox" id="Basic Investing" />
                <p className="ml-2 text-white">Basic Investing</p>
              </div>
              <div className="flex ">
                <input class="check" type="checkbox" id="Futures" />
                <p className="ml-2 text-white">Futures</p>
              </div>
            </div>
          </div>
          <h1 className="text-center text-2xl text-white mt-12">
            Please enter your location
          </h1>
          <div className="grid mt-4">
            <input
              className="m-auto w-52 text-xl poppins-regular text-center border-b-2 border-white text-white bg-secondary focus:outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              type="text"
              id="location"
            />
          </div>
          <h1 className="text-center text-2xl text-white mt-12">
            Please enter any additional comments
          </h1>
          <div className="grid mt-4">
            <textarea
              className="m-auto sm:w-1/3 h-80 bg-white poppins-regular border-4 border-black text-black bg-secondary focus:outline-none"
              type="text"
              id="comments"
            />
          </div>
          <div className="mt-12 text-center">
            <button
              onClick={submitForm}
              className="bg-main text-white p-2 rounded-xl cursor-pointer"
            >Submit Form</button>
          </div>
        </div>
      </div>
    );
  } else if (!loading && message) {
    return (
      <div className="bg-secondary text-center pt-80 pb-80">
        {" "}
        <h1 className="text-3xl text-white poppins-bold pt-4 pb-4">
          {message}
        </h1>
        {message.includes("Error") ? <p></p> : <p>Thank you for submitting a form. We will be emailing updates.</p>}
      </div>
    );
  } else {
    return (
      <div className="bg-secondary text-center pt-80 pb-80">
        <h1 className="text-3xl text-white poppins-bold">Submitting Form</h1>
        <div className="flex justify-center mt-1">
          <div className="">
            <svg
              aria-hidden="true"
              class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }
};

export default Form;
